
import {Options, Vue} from 'vue-class-component';
import {colorLog} from "@/utils";
import {ViewNames} from "@/router"
import FadeTransition from "@/components/transitions/FadeTransition.vue";
import SubViewTemplate from "@/views/templates/SubViewTemplate.vue";

@Options<IndividualHome>({
  components: {
    SubViewTemplate,
    FadeTransition
  },
  props: {}
})
export default class IndividualHome extends Vue {
  ViewNames = ViewNames;
}
