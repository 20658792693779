import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SubViewTemplate = _resolveComponent("SubViewTemplate")!

  return (_openBlock(), _createBlock(_component_SubViewTemplate, {
    class: "individual-home",
    include: _ctx.ViewNames.Creations,
    "views-info": [
                       {
                         viewName:_ctx.ViewNames.Creations,
                         image:'image',
                         path:'creations'
                       },
                       {
                         viewName:_ctx.ViewNames.Profile,
                         image:'profile',
                         path:'profile'
                       },
                       // {
                       //   viewName:ViewNames.BillingInfo,
                       //   image:'credit',
                       //   path:'billingInfo'
                       // }
                   ]
  }, null, 8, ["include", "views-info"]))
}